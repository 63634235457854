// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/pc-big.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".indexbackground[data-v-1ab2c83a]{padding:38px 16px 38px 24px}[data-v-1ab2c83a] .t-dialog{border:none;box-shadow:0 2px 20px 0 rgba(0,0,0,.1)}[data-v-1ab2c83a] .t-dialog--default{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:100% 100%;padding:0}[data-v-1ab2c83a] .t-dialog__body{border-radius:8px;padding:0}.title[data-v-1ab2c83a]{color:#000;font-size:20px;font-weight:600}.text[data-v-1ab2c83a]{color:#4c628e;font-size:14px;font-weight:400;line-height:23px;max-width:260px;padding-bottom:22px;padding-top:18px}.telephone[data-v-1ab2c83a]{border-bottom:1px solid #0081ff;color:#0081ff;-webkit-text-decoration:none;text-decoration:none}.telephone[data-v-1ab2c83a],.telephone1[data-v-1ab2c83a]{font-size:15px;font-weight:600;line-height:22px}.telephone1[data-v-1ab2c83a]{align-items:center;color:#08265e;display:flex;margin-bottom:33px}.line[data-v-1ab2c83a]{background:#08265e;border-radius:2px;height:2px;width:20px}.logo[data-v-1ab2c83a]{bottom:16px;position:absolute;right:16px}.tel[data-v-1ab2c83a]{height:24px;vertical-align:middle;width:24px}.close[data-v-1ab2c83a]{cursor:pointer;height:20px;position:absolute;right:16px;top:16px;width:20px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
