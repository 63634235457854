// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/mg-big.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mobilebackground[data-v-4cf53382]{border-radius:24px;padding:32px 16px 32px 24px;position:relative}[data-v-4cf53382] .t-dialog--default{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:100% 100%;border-radius:24px;padding:0}[data-v-4cf53382] .t-dialog__body{padding:0}[data-v-4cf53382] .t-dialog{border:none;box-shadow:0 2px 20px 0 rgba(0,0,0,.1)}.title[data-v-4cf53382]{color:#000;font-size:20px;font-weight:600}.text[data-v-4cf53382]{color:#4c628e;font-size:14px;font-weight:400;line-height:23px;margin-bottom:22px;margin-top:18px}.telephone[data-v-4cf53382]{border-bottom:1px solid #0081ff;color:#0081ff;-webkit-text-decoration:none;text-decoration:none}.telephone[data-v-4cf53382],.telephone1[data-v-4cf53382]{font-size:15px;font-weight:600;line-height:22px}.telephone1[data-v-4cf53382]{align-items:center;color:#08265e;display:flex;margin-bottom:33px}.line[data-v-4cf53382]{background:#c2c9d6;border-radius:2px;height:2px;width:20px}.tel[data-v-4cf53382]{height:24px;vertical-align:middle;width:24px}.close[data-v-4cf53382]{cursor:pointer;height:20px;position:absolute;right:16px;top:16px;width:20px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
