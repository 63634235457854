<template>
  <div class="index_bigbox">
    <a
      id="open-app"
      style="position: absolute; z-index: -1; opacity: 0"
      :href="'ffsayim://com.life.ffsay/openwith?type=addFriend&code=' + code"
      >扫一扫</a
    >
    <t-dialog
      :visible="downloadVisible"
      :header="$t('download.abanben')"
      :confirm-btn="$t('header.download')"
      :cancelBtn="null"
      :closeBtn="false"
      :close-on-overlay-click="false"
    >
      <div slot="body">
        <div class="android_text" style="margin-top: 25px">
          {{ $t("index2.jkty") }}，{{ $t("index2.yzqxd") }}
        </div>
      </div>
      <div slot="footer">
        <t-button @click="handleOpenAppByScheme">打开APP</t-button>
        <t-button @click="handleDownload">{{ $t("header.download") }}</t-button>
      </div>
    </t-dialog>
    <div v-if="maskVisible" class="install-mask">
      <img class="arrow" src="../../assets/images/arrow.png" alt="" />
      <div class="mask-title">如何在浏览器中打开？</div>
      <div class="sub-title1">
        1.点击右上角的
        <img src="../../assets/images/point.png" alt="" />
      </div>
      <div class="sub-title2">2.选择在浏览器中打开</div>
      <div class="open-brower-btn">在浏览器中打开</div>
      <div class="close-mask-btn" @click="maskVisible = false">知道了</div>
    </div>
    <IndexDialog
      v-if="!isMobile && $i18n.locale !== 'ja'"
      ref="indexDialog"
    ></IndexDialog>
    <div id="index" class="index">
      <div class="index_banner1">
        <div class="index_banner1_box">
          <div class="index_banner1_box_leftbox">
            <div class="index_banner1_box_leftbox_title">
              <span style="color: #0081ff">{{ $t("index1.private") }}</span>
              <span style="color: #1f2329">{{ $t("index1.chat") }}</span>
            </div>
            <div class="index_banner1_box_leftbox_bigtitle">
              <div>{{ $t("index1.bhndltys") }}</div>
              <div>{{ $t("index1.bnxxdgaq") }}</div>
            </div>
            <div class="index_banner1_box_leftbox_text">
              <div>{{ $t("index1.dyhdgtfs") }}</div>
              <div v-html="$t('index1.ltxxjm')"></div>
              <div>{{ $t("index1.jkkq") }}</div>
            </div>
            <div class="index_banner1_box_leftbox_btnbox">
              <div
                class="btnbox"
                style="background: #0081ff; color: #fff"
                @click="toDownload"
              >
                <span>{{ $t("header.download") }} </span>
                <img src="../../static/img/index/download.png" alt="" />
              </div>
              <div
                class="btnbox blackbox"
                style="
                  border-radius: 38px;
                  border: 3px solid #1a2236;
                  color: #1a2236;
                  width: 249px;
                  height: 69px;
                "
                @click="toProduct"
              >
                {{ $t("header.product") }}
              </div>
            </div>
          </div>
          <div class="index_banner1_box_img">
            <img src="../../static/img/index/banner1.svg" alt="" />
          </div>
        </div>
      </div>
      <div class="index_banner2">
        <div class="index_banner2_box">
          <div class="index_banner2_box_leftbox">
            <img :src="banner2left" alt="" />
          </div>
          <div class="index_banner2_box_centerbox">
            <div class="index_banner2_box_centerbox_title">
              <div>{{ $t("index2.jkty") }}</div>
              <div>{{ $t("index2.yzqxd") }}</div>
            </div>
            <div class="index_banner2_box_centerbox_text">
              <div>{{ $t("index2.ffdgnsy") }}</div>
              <div>{{ $t("index2.xxksgx") }}</div>
              <div>{{ $t("index2.qgcjm") }}</div>
            </div>
            <div class="index_banner2_box_centerbox_jiantou">
              <img
                src="../../static/img/index/jiantou.png"
                alt=""
                @click="toTitle"
                style="cursor: pointer"
              />
              <div class="index_banner2_box_centerbox_jiantou_down">
                <img src="../../static/img/index/down.png" alt="" />
                <div>{{ $t("index2.gdsb") }}</div>
              </div>
            </div>
          </div>
          <div class="index_banner2_box_rightbox">
            <img src="../../static/img/index/banner2right.png" alt="" />
          </div>
        </div>
      </div>

      <div class="index_banner3">
        <div class="index_banner3_title">
          {{ $t("index2.wmdynxgn") }}
        </div>
        <div class="index_banner3_box">
          <div class="index_banner3_box_leftbox">
            <img :src="banner3left" alt="" />
          </div>
          <div class="index_banner3_box_rightbox">
            <div class="index_banner3_box_rightbox_title">
              <div>{{ $t("index2.syhbs") }}</div>
              <div>{{ $t("index2.fwq") }}</div>
            </div>
            <div class="index_banner3_box_rightbox_text">
              <div v-html="$t('index2.yhsjzk')"></div>
              <div>{{ $t("index2.lhbssy") }}</div>
              <div>{{ $t("index2.1xskt") }}</div>
            </div>
            <div
              class="index_banner3_box_rightbox_next"
              @click="toGroup"
              style="margin-left: -27px"
            >
              <img src="../../static/img/index/next.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="index_banner4">
        <div class="index_banner4_box">
          <div class="index_banner4_box_leftbox">
            <div class="index_banner4_box_leftbox_title">
              <div>{{ $t("index2.qzyygn") }}</div>
              <div>{{ $t("index2.gqd") }}</div>
            </div>
            <div class="index_banner4_box_leftbox_text">
              <div v-html="$t('index2.dgqzzd')"></div>
              <div>{{ $t("index2.qglyyy") }}</div>
              <div>{{ $t("index2.bznyxgl") }}</div>
            </div>
            <div class="index_banner4_box_leftbox_next" @click="toChat">
              <img src="../../static/img/index/next.svg" alt="" />
            </div>
          </div>
          <div class="index_banner4_box_rightbox">
            <img :src="banner4right" alt="" />
          </div>
        </div>
      </div>
      <div class="index_banner3 index_banner5">
        <div class="index_banner3_box" style="margin-top: 0px">
          <div
            class="index_banner3_box_leftbox"
            style="width: 506px; height: 479px"
          >
            <img :src="banner5left" alt="" />
          </div>
          <div class="index_banner3_box_rightbox">
            <div class="index_banner3_box_rightbox_title">
              <div>{{ $t("index2.ltwy") }}</div>
              <div>{{ $t("index2.yjqh") }}</div>
            </div>
            <div class="index_banner3_box_rightbox_text">
              <div>{{ $t("index2.appnz") }}</div>
              <div>{{ $t("index2.wxdyqt") }}</div>
              <div>{{ $t("index2.qssxlt") }}</div>
            </div>
            <div
              class="index_banner3_box_rightbox_next"
              @click="toFriend"
              style="margin-left: -27px"
            >
              <img src="../../static/img/index/next.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="index_banner4 index_banner6">
        <div class="index_banner4_box">
          <div class="index_banner4_box_leftbox">
            <div class="index_banner4_box_leftbox_title">
              <div>{{ $t("index2.hyzxlx") }}</div>
              <div>{{ $t("index2.glsx") }}</div>
            </div>
            <div class="index_banner4_box_leftbox_text">
              <div>{{ $t("index2.hylbxs") }}</div>
              <div>{{ $t("index2.wxwxdd") }}</div>
            </div>
            <div class="index_banner4_box_leftbox_next" @click="toPiliang">
              <img src="../../static/img/index/next.svg" alt="" />
            </div>
          </div>
          <div
            class="index_banner4_box_rightbox"
            style="width: 682px; height: 518px"
          >
            <img :src="banner6right" alt="" />
          </div>
        </div>
      </div>
      <div class="index_banner3 index_banner7">
        <div class="index_banner3_box" style="margin-top: 0px">
          <div
            class="index_banner3_box_leftbox"
            style="width: 472px; height: 432px"
          >
            <img :src="banner7left" alt="" />
          </div>
          <div class="index_banner3_box_rightbox">
            <div class="index_banner3_box_rightbox_title">
              <div>{{ $t("index2.plfsxx") }}</div>
              <div>{{ $t("index2.bxgx") }}</div>
            </div>
            <div class="index_banner3_box_rightbox_text">
              <div>{{ $t("index2.zdtdhy") }}</div>
              <div v-html="$t('index2.ksfstz')"></div>
              <div>{{ $t("index2.tgnbwb") }}</div>
            </div>
            <div
              class="index_banner3_box_rightbox_next"
              @click="toLanguage"
              style="margin-left: -27px"
            >
              <img src="../../static/img/index/next.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="index_banner4 index_banner8">
        <div class="index_banner4_box">
          <div class="index_banner4_box_leftbox">
            <div class="index_banner4_box_leftbox_title">
              <div>{{ $t("index2.ltdh") }}</div>
              <div>{{ $t("index2.dyyzc") }}</div>
            </div>
            <div class="index_banner4_box_leftbox_text">
              <div>{{ $t("index2.wjjbtl") }}</div>
              <div>{{ $t("index2.zcdggj") }}</div>
              <div>{{ $t("index2.zxxdh") }}</div>
              <div>{{ $t("index2.qqckhl") }}</div>
            </div>
            <div class="index_banner4_box_leftbox_next" @click="toDownload">
              <img src="../../static/img/index/next.svg" alt="" />
            </div>
          </div>
          <div
            class="index_banner4_box_rightbox"
            style="width: 561px; height: 440px"
          >
            <img :src="banner8right" alt="" />
          </div>
        </div>
      </div>
      <div class="index_download">
        <div class="index_download_box">
          <div class="index_download_box_title">
            {{ $t("download.khdxz") }}
          </div>
          <div class="index_download_box_text">
            {{ $t("download.zzios") }}
          </div>
          <div class="index_download_box_btnbox">
            <div
              class="index_download_box_btnbox_item"
              @mouseenter="() => (chooseapple = true)"
              v-show="chooseapple === false"
            >
              <img src="../../static/img/index/appstore.svg" alt="" />
              <div>{{ $t("download.AppStore") }}</div>
            </div>
            <div
              class="activeitem"
              @click="downloadIos('/install/ios')"
              v-show="chooseapple === true"
              @mouseleave="chooseapple = false"
            >
              <img src="../../static/img/index/chooseappstore.png" alt="" />
              <div class="activeitem_right">
                <div class="activeitem_right_top">
                  {{ $t("download.AppStore") }}
                </div>
                <div class="activeitem_right_bottom">
                  {{ $t("download.qsdxz") }}
                </div>
              </div>
            </div>
            <div
              class="index_download_box_btnbox_item"
              @mouseenter="() => (choosegoogle = true)"
              v-show="choosegoogle === false"
            >
              <img src="../../static/img/index/google.svg" alt="" />
              <div>{{ $t("download.Google") }}</div>
            </div>
            <div
              @click="downloadApp('/install/googleplay')"
              class="activeitem"
              v-show="choosegoogle === true"
              @mouseleave="choosegoogle = false"
            >
              <img src="../../static/img/index/googleplayActive.svg" alt="" />
              <div class="activeitem_right">
                <div class="activeitem_right_top">
                  {{ $t("download.Google") }}
                </div>
                <div class="activeitem_right_bottom">
                  {{ $t("download.qsdxz") }}
                </div>
              </div>
            </div>
            <div
              class="index_download_box_btnbox_item"
              @mouseenter="() => (chooseApk = true)"
              v-show="chooseApk === false"
            >
              <img src="../../static/img/index/apk.svg" alt="" />
              <div>{{ $t("download.Apk") }}</div>
            </div>
            <div
              @click="downloadApp('/install/Android')"
              class="activeitem"
              v-show="chooseApk === true"
              @mouseleave="chooseApk = false"
            >
              <img src="../../static/img/index/apkActive.svg" alt="" />
              <div class="activeitem_right">
                <div class="activeitem_right_top">{{ $t("download.Apk") }}</div>
                <div class="activeitem_right_bottom">
                  {{ $t("download.djxz") }}
                </div>
              </div>
            </div>

            <div
              class="index_download_box_btnbox_item"
              @mouseenter="() => (choosewindow = true)"
              v-show="choosewindow === false"
            >
              <img src="../../static/img/index/windows.svg" alt="" />
              <div>{{ $t("download.Windows") }}</div>
            </div>
            <div
              @click="downloadApp('/install/Windows')"
              class="activeitem newact"
              v-show="choosewindow === true"
              @mouseleave="choosewindow = false"
              :class="$i18n.locale === 'ja' ? 'small' : ''"
            >
              <img src="../../static/img/index/winActive.svg" alt="" />
              <!-- <div class="activeitem_right">
                {{ $t('download.djxz') }}
              </div> -->
              <div class="activeitem_right">
                <div class="activeitem_right_top">
                  {{ $t("download.Windows") }}
                </div>
                <div class="activeitem_right_bottom">
                  {{ $t("download.djxz") }}
                </div>
              </div>
            </div>
            <div
              class="index_download_box_btnbox_item"
              @mouseenter="() => (choosemac = true)"
              v-show="choosemac === false"
            >
              <img src="../../static/img/index/macOS.svg" alt="" />
              <div>{{ $t("download.macOS") }}</div>
            </div>
            <div
              @click="downloadApp('/install/Mac')"
              class="activeitem"
              v-show="choosemac === true"
              @mouseleave="choosemac = false"
              :class="$i18n.locale === 'ja' ? 'small2' : ''"
            >
              <img
                src="../../static/img/index/osActive.svg"
                alt=""
                :class="$i18n.locale === 'ja' ? 'img2' : ''"
              />
              <!-- <div class="activeitem_right" :class="$i18n.locale === 'ja' ? 'right2' : ''">
                {{ $t('download.djxz') }}
              </div> -->
              <div class="activeitem_right">
                <div class="activeitem_right_top">
                  {{ $t("download.macOS") }}
                </div>
                <div class="activeitem_right_bottom">
                  {{ $t("download.djxz") }}
                </div>
              </div>
            </div>
          </div>
          <div class="index_download_box_imgbox">
            <img
              src="../../static/img/index/downloadleft.svg"
              alt=""
              style="width: 374px"
            />
            <img
              src="../../static/img/index/downloadright.svg"
              alt=""
              style="width: 781px; margin-left: 62px"
            />
          </div>
        </div>
      </div>
      <div class="dialogpic"  v-fixed="{ bottom: 64 }" @click="openIndexDialog" v-if="$i18n.locale!=='ja'">
        <img :src="footerpic" alt="" />
      </div>
    </div>
    <MobileDialog
      v-if="isMobile && $i18n.locale !== 'ja'"
      ref="mobileDialog"
    ></MobileDialog>
    <div class="mindex" ref="mindex">
      <div class="mindex_box">
        <div class="mindex_box_banner1">
          <div class="banner1">
            <img
              src="../../static/img/index/banner1.svg"
              alt=""
              class="banner1_img"
            />
            <div class="banner1_stitle">
              <span style="color: #0081ff">{{ $t("index1.private") }}</span>
              <span style="margin-left: 3px">{{ $t("index1.chat") }}</span>
            </div>
            <div class="banner1_btitle">
              <div>{{ $t("index1.bhndltys") }}</div>
              <div>{{ $t("index1.bnxxdgaq") }}</div>
            </div>
            <div class="banner1_text">
              <div>{{ $t("index1.dyhdgtfs") }}</div>
              <div>{{ $t("index1.ltxxjm") }}</div>
              <div>{{ $t("index1.jkkq") }}</div>
            </div>
            <div class="banner1_btnbox">
              <div class="banner1_btnbox_item1" @click="tohDownload">
                <div>{{ $t("header.download") }}</div>
                <img src="../../static/img/mobile-index/download.png" alt="" />
              </div>
              <div class="banner1_btnbox_item2" @click="goDetail">
                <div>{{ $t("header.product") }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="mindex_box_banner2" ref="banner2">
          <div class="banner2">
            <div class="banner2_btitle">
              <div>{{ $t("index2.jkty") }}</div>
              <div>{{ $t("index2.yzqxd") }}</div>
            </div>
            <div class="banner2_text">
              <div>{{ $t("index2.ffdgnsy") }}</div>
              <div>{{ $t("index2.xxksgx") }}</div>
              <div>{{ $t("index2.qgcjm") }}</div>
            </div>
            <div class="banner2_img">
              <img :src="mobile_banner2" alt="" />
            </div>
          </div>
        </div>
        <div class="mindex_box_banner3">
          <div class="banner3">
            <div class="banner3_box">
              <div class="banner3_box_btitle">
                {{ $t("index2.wmdynxgn") }}
              </div>
              <div class="banner3_box_content">
                <div class="banner3_box_content_title">
                  {{ $t("index2.syhbs") + $t("index2.fwq") }}
                </div>
                <div class="banner3_box_content_text">
                  <div>{{ $t("index2.yhsjzk") }}</div>
                  <div>{{ $t("index2.lhbssy") }}</div>
                  <div>{{ $t("index2.1xskt") }}</div>
                </div>
                <img class="banner3_img1" :src="banner3left" alt="" />
              </div>
              <div class="banner3_box_content">
                <div class="banner3_box_content_title" style="margin-top: 67px">
                  {{ $t("index2.qzyygn") }}
                  {{ $t("index2.gqd") }}
                </div>
                <div class="banner3_box_content_text">
                  <div>{{ $t("index2.dgqzzd") }}</div>
                  <div>{{ $t("index2.qglyyy") }}</div>
                  <div>{{ $t("index2.bznyxgl") }}</div>
                </div>
                <img class="banner3_img2" :src="banner4right" alt="" />
              </div>
              <div class="banner3_box_content">
                <div class="banner3_box_content_title" style="margin-top: 59px">
                  {{ $t("index2.ltwy") }}
                  {{ $t("index2.yjqh") }}
                </div>
                <div class="banner3_box_content_text">
                  <div>{{ $t("index2.appnz") }}</div>
                  <div>{{ $t("index2.wxdyqt") }}</div>
                  <div>{{ $t("index2.qssxlt") }}</div>
                </div>
                <img class="banner3_img3" :src="banner5left" alt="" />
              </div>
              <div class="banner3_box_content">
                <div class="banner3_box_content_title" style="margin-top: 58px">
                  <div>{{ $t("index2.hyzxlx") }}</div>
                  <div>{{ $t("index2.glsx") }}</div>
                </div>
                <div class="banner3_box_content_text">
                  <div>{{ $t("index2.hylbxs") + $t("index2.wxwxdd") }}</div>
                </div>
                <img class="banner3_img4" :src="banner6right" alt="" />
              </div>
              <div class="banner3_box_content">
                <div class="banner3_box_content_title" style="margin-top: 58px">
                  <div>
                    {{ $t("index2.plfsxx") }}&nbsp;{{ $t("index2.bxgx") }}
                  </div>
                </div>

                <div class="banner3_box_content_text">
                  <div>{{ $t("index2.zdtdhy") }}</div>
                  <div>{{ $t("index2.ksfstz") }}</div>
                  <div>{{ $t("index2.tgnbwb") }}</div>
                </div>
                <img
                  :src="banner7left"
                  alt=""
                  style="width: 322px; margin-top: 30px; margin-left: 18px"
                />
              </div>
              <div class="banner3_box_content">
                <div class="banner3_box_content_title" style="margin-top: 56px">
                  <div>
                    {{ $t("index2.ltdh") }}&nbsp;{{ $t("index2.dyyzc") }}
                  </div>
                </div>

                <div class="banner3_box_content_text">
                  <div>{{ $t("index2.wjjbtl") }}</div>
                  <div>{{ $t("index2.zcdggj") }}</div>
                  <div>{{ $t("index2.zxxdh") }}</div>
                  <div>{{ $t("index2.qqckhl") }}</div>
                </div>
                <img class="banner3_img5" :src="banner8right" alt="" />
              </div>
            </div>
          </div>
        </div>
        <MobileBtn></MobileBtn>
        <MobileFooter @toGN="toGN"></MobileFooter>
      </div>
      <div class="mobiledialogpic" @click="openMobileDialog" v-if="$i18n.locale!=='ja'">
        <img :src="footerpic" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import MobileBtn from "../../components/MobileBtn.vue";
import MobileFooter from "../../components/MobileFooter.vue";
import IndexDialog from "../../components/IndexDialog.vue";
import MobileDialog from "../../components/MobileDialog.vue";
import { isMobile } from "@/utils/tools.js";

export default {
  name: "index",
  components: { MobileBtn, MobileFooter, IndexDialog, MobileDialog },
  data() {
    return {
      ifshow: true,
      downloadVisible: false,
      maskVisible: false,
      banner2left: require(`../../static/img/lang/${this.$i18n.locale}/index/banner2left.svg`),
      banner3left: require(`../../static/img/lang/${this.$i18n.locale}/index/banner3left.svg`),
      banner4right: require(`../../static/img/lang/${this.$i18n.locale}/index/banner4right.svg`),
      banner5left: require(`../../static/img/lang/${this.$i18n.locale}/index/banner5left.svg`),
      banner6right: require(`../../static/img/lang/${this.$i18n.locale}/index/banner6right.svg`),
      banner7left: require(`../../static/img/lang/${this.$i18n.locale}/index/banner7left.svg`),
      banner8right: require(`../../static/img/lang/${this.$i18n.locale}/index/banner8right.svg`),
      mobile_banner2: require(`../../static/img/lang/${this.$i18n.locale}/index/banner2.png`),
      footerpic: require(`../../static/img/lang/${this.$i18n.locale}/index/footer.png`),
      choosegoogle: false,
      chooseApk: false,
      chooseapple: false,
      choosewindow: false,
      choosemac: false,
      isMobile: false,
      code: ''
    };
  },
  asyncData({
    isDev,
    route,
    store,
    env,
    params,
    query,
    req,
    res,
    redirect,
    error,
  }) {},
  mounted() {
    const code = this.GetQueryString("code");
    this.isMobile = isMobile();
    if (code) {
      this.code = code
      this.openApp(this.getSchemeLing(), 2000, () => {
        // 唤起失败，执行处理逻辑
        var ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf("micromessenger") === -1 && !this.isIos()) {
          this.downloadVisible = true;
        }
      });
    }
  },
  methods: {
    openIndexDialog() {
      this.$refs.indexDialog.open();
    },
    openMobileDialog() {
      this.$refs.mobileDialog.open();
    },
    getSchemeLing() {
      return (
        "ffsayim://com.life.ffsay/openwith?type=addFriend&code=" +
        this.GetQueryString("code")
      );
    },
    downloadApk() {
      window.open(`${location.protocol}//${location.host}/install/Android`);
    },
    handleDownload() {
      this.downloadVisible = false;
      this.clipboardWright();
      this.downloadApk();
    },
    clipboardWright() {
      if (navigator.clipboard && window.isSecureContext) {
        // navigator clipboard 向剪贴板写文本
        return navigator.clipboard.writeText(this.getSchemeLing());
      } else {
        // 创建text area
        let textArea = document.createElement("textarea");
        textArea.value = this.getSchemeLing();
        // 使text area不在viewport，同时设置不可见
        textArea.style.position = "absolute";
        textArea.style.opacity = 0;
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
          // 执行复制命令并移除文本框
          document.execCommand("copy") ? res() : rej();
          textArea.remove();
        });
      }
    },
    GetQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg); //获取url中"?"符后的字符串并正则匹配
      var context = "";
      if (r != null) context = decodeURIComponent(r[2]);
      reg = null;
      r = null;
      return context == null || context == "" || context == "undefined"
        ? ""
        : context;
    },
    isIos() {
      return /iPhone|iPad|iPod/i.test(navigator.userAgent);
    },
    handleOpenAppByScheme() {
      const openApp = document.getElementById("open-app");
      openApp.click();
    },
    openApp(schemeUrl, timeout, failCallback) {
      if (!/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
        return;
      }
      var ua = navigator.userAgent.toLowerCase();
      var startTime = Date.now();
      var iframe;
      var timer;
      // 微信浏览器，采用JSAPI方式唤起微信APP
      if (ua.indexOf("micromessenger") !== -1) {
        this.maskVisible = true;
      } else {
        if (!this.isIos()) {
          // 其他浏览器，采用URL Scheme方式唤起APP
          // iframe = document.createElement('iframe');
          // iframe.style.display = 'none';
          // iframe.src = schemeUrl;
          // document.body.appendChild(iframe);
          this.handleOpenAppByScheme();
        }
      }
      timer = setTimeout(function () {
        var endTime = Date.now();
        if (endTime - startTime < timeout + 200) {
          // APP未唤起，执行失败回调函数
          failCallback && failCallback();
        }
      }, timeout);
      window.onblur = function () {
        clearTimeout(timer);
      };
      window.onfocus = function () {
        clearTimeout(timer);
      };
    },
    goDetail() {
      const detailDom = this.$refs.banner2;
      console.log(detailDom.offsetTop, document.querySelector(".mindex"));
      if (detailDom) {
        this.$refs.mindex.scrollTo(0, detailDom.offsetTop - 100);
      }
    },
    downloadIos() {
      const appStoreUrl = "https://itunes.apple.com/app/id6461414837";
      window.open(appStoreUrl);
    },
    downloadApp(link) {
      window.open(`${location.protocol}//${location.host}${link}`);
    },
    tohDownload() {
      const userAgent = navigator.userAgent.toLowerCase();
      if (/android/.test(userAgent)) {
        // 手机是安卓设备
        console.log("安卓");
        const path = `${
          this.$i18n.locale === "vi" ? "" : "/" + this.$i18n.locale
        }${"/android"}`;
        this.$router.push({
          path: path,
        });
      } else if (/iphone|ipad|ipod/.test(userAgent)) {
        // 手机是苹果设备
        const path = `${
          this.$i18n.locale === "vi" ? "" : "/" + this.$i18n.locale
        }${"/ios"}`;
        console.log("苹果", path);
        this.$router.push({
          path: path,
        });
      } else {
        // 其他设备
        console.log("其他设备");
      }
    },
    toGN() {
      const mindex = this.$refs.mindex;
      const banner2 = this.$refs.banner2;
      const top = banner2.offsetTop - mindex.offsetTop;
      console.log(top);
      mindex.scrollTo({
        top: top,
        left: 0,
        behavior: "smooth",
      });
    },
    toProduct() {
      const banner2 = document.querySelector(".index_banner2").offsetTop - 72;
      window.scrollTo({
        top: banner2,
        left: 0,
        behavior: "smooth",
      });
    },
    toTitle() {
      const title = document.querySelector(".index_banner3_title").offsetTop;
      window.scrollTo({
        top: title - 72,
        left: 0,
        behavior: "smooth",
      });
    },
    toGroup() {
      const Group = document.querySelector(".index_banner4").offsetTop;
      window.scrollTo({
        top: Group - 72,
        left: 0,
        behavior: "smooth",
      });
    },
    toChat() {
      const Chat = document.querySelector(".index_banner5").offsetTop;
      window.scrollTo({
        top: Chat - 72,
        left: 0,
        behavior: "smooth",
      });
    },
    toFriend() {
      const Friend = document.querySelector(".index_banner6").offsetTop;
      window.scrollTo({
        top: Friend - 72,
        left: 0,
        behavior: "smooth",
      });
    },
    toPiliang() {
      const Piliang = document.querySelector(".index_banner7").offsetTop;
      window.scrollTo({
        top: Piliang - 72,
        left: 0,
        behavior: "smooth",
      });
    },
    toLanguage() {
      const Language = document.querySelector(".index_banner8").offsetTop;
      window.scrollTo({
        top: Language - 72,
        left: 0,
        behavior: "smooth",
      });
    },
    toDownload() {
      console.log("download");
      const Download = document.querySelector(".index_download").offsetTop;
      // window.scroll({
      //   top: Download - 72,
      //   left: 0,
      //   behavior: 'smooth'
      // });
      window.scroll({
        top: Download - 72,
        left: 0,
        behavior: "smooth", //滚动条平滑滚动
      });
    },
  },
};
</script>

<style lang="less" scoped>
.index {
  width: 100%;
  position: relative;

  .dialogpic {
    position: fixed;
    right: 56px;
    cursor: pointer;
    width: 96px;
    height: 96px;
    display: flex;
    align-items: center;
    justify-content: center;
    > img {
      width: 100%;
      height: 100%;
    }
  }
  &_banner1 {
    height: 854px;
    width: 100%;
    background: url("../../static/img/index/banner1bg8.png") no-repeat center;
    background-size: 100% 856px;

    &_box {
      width: 1300px;
      margin: auto;
      display: flex;
      justify-content: space-between;

      &_leftbox {
        margin-left: 68px;

        &_title {
          margin-top: 76px;
          font-size: 24px;
          font-weight: 600;
          text-shadow: 0px 2px 40px rgba(47, 88, 248, 0.2);
        }

        &_bigtitle {
          width: 460px;
          margin-top: 24px;
          font-size: 40px;
          color: #000000;
          line-height: 56px;
          text-shadow: 0px 2px 40px rgba(47, 88, 248, 0.2);
        }

        &_text {
          width: 590px;
          margin-top: 24px;
          font-size: 18px;
          color: #51565d;
          line-height: 36px;
        }

        &_btnbox {
          margin-top: 47px;
          height: 75px;
          display: flex;

          .btnbox {
            cursor: pointer;
            width: 255px;
            height: 75px;
            box-shadow: 0px 2px 40px 0px rgba(47, 88, 248, 0.2),
              20px 24px 30px 0px rgba(71, 108, 255, 0.2);
            border-radius: 38px;
            text-align: center;
            font-weight: 600;
            margin-right: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;

            img {
              width: 14px;
              height: 14px;
              margin-left: 10px;
            }
          }

          .blackbox {
            background-color: rgba(237, 241, 254, 0);
          }
        }
      }

      &_img {
        width: 579px;
        height: 579px;
        margin-top: 74px;

        img {
          width: 100%;
        }
      }
    }
  }

  &_banner2 {
    height: 813px;
    width: 100%;
    background: url("../../static/img/index/banner2bg.png") no-repeat center;
    background-size: 100% 816px;

    &_box {
      width: 1300px;
      margin: auto;
      display: flex;
      justify-content: space-between;
      position: relative;

      &_leftbox {
        width: 450px;
        height: 586px;
        margin-left: 68px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      &_centerbox {
        position: absolute;
        left: 50%;
        width: 360px;
        margin-left: -180px;
        top: 200px;

        &_title {
          width: 100%;
          text-align: center;
          font-size: 40px;
          color: #000000;
          line-height: 56px;
        }

        &_text {
          font-size: 18px;
          font-weight: 400;
          color: #000000;
          line-height: 38px;
          text-align: center;
          margin-top: 32px;
        }

        &_jiantou {
          margin: auto;
          margin-top: 8px;
          width: 148px;
          height: 148px;

          img {
            width: 100%;
            height: 100%;
          }

          &_down {
            display: flex;
            align-items: center;
            margin: auto;

            img {
              width: 36px;
              height: 36px;
            }

            div {
              font-size: 16px;
              color: #000000;
              margin-left: 10px;
            }
          }
        }
      }

      &_rightbox {
        width: 532px;
        height: 672px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &_banner3 {
    height: 698px;
    width: 100%;
    background: url("../../static/img/index/banner3bg.png") no-repeat center;
    background-size: 100% 700px;

    &_title {
      font-size: 40px;
      font-weight: 600;
      text-align: center;
      color: #0a1d4c;
      padding-top: 30px;
    }

    &_box {
      width: 1300px;
      margin: auto;
      display: flex;
      margin-top: 74px;

      &_leftbox {
        margin-left: 88px;
        width: 506px;
        height: 418px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      &_rightbox {
        margin-left: 115px;
        margin-top: 75px;

        &_title {
          font-size: 38px;
          color: #1f2329;
          line-height: 60px;
        }

        &_text {
          margin-top: 19px;
          font-size: 20px;
          color: #7c7c7c;
          line-height: 36px;
        }

        &_next {
          margin-top: 30px;
          cursor: pointer;
        }
      }
    }
  }

  &_banner4 {
    height: 698px;
    width: 100%;
    background: url("../../static/img/index/banner4bg.png") no-repeat center;
    background-size: 100% 700px;

    &_box {
      width: 1300px;
      margin: auto;
      display: flex;

      &_leftbox {
        margin-left: 135px;
        margin-top: 75px;

        &_title {
          font-size: 38px;
          color: #000000;
          line-height: 60px;
        }

        &_text {
          margin-top: 37px;
          font-size: 20px;
          color: #51565d;
          line-height: 36px;
        }

        &_next {
          margin-top: 30px;
          cursor: pointer;
          margin-left: -27px;
        }
      }

      &_rightbox {
        width: 587px;
        height: 492px;
        margin-left: 34px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &_banner5 {
    height: 610px;
    width: 100%;
    background: url("../../static/img/index/banner5bg.png") no-repeat center;
    background-size: 100% 612px;
  }

  &_banner6 {
    height: 611px;
    width: 100%;
    background: url("../../static/img/index/banner6bg.png") no-repeat center;
    background-size: 100% 613px;
  }

  &_banner7 {
    height: 610px;
    width: 100%;
    background: url("../../static/img/index/banner7bg.png") no-repeat center;
    background-size: 100% 613px;
  }

  &_banner8 {
    height: 610px;
    width: 100%;
    background: url("../../static/img/index/banner8bg.png") no-repeat center;
    background-size: 100% 613px;
  }

  &_download {
    height: 853px;
    width: 100%;
    background: url("../../static/img/index/downloadbg.png") no-repeat center;
    background-size: 100% 855px;

    &_box {
      width: 1300px;
      margin: auto;

      &_title {
        text-align: center;
        font-size: 54px;
        font-weight: 600;
        color: #0a1d4c;
      }

      &_text {
        text-align: center;
        margin-top: 24px;
        font-size: 22px;
        color: #000000;
        letter-spacing: 2px;
      }

      &_btnbox {
        display: flex;
        margin: auto;
        justify-content: space-between;
        margin-top: 60px;
        width: 1055px;

        &_item {
          width: 206px;
          cursor: pointer;
          height: 60px;
          border-radius: 8px;
          border: 1px solid #000000;
          display: flex;
          align-items: center;
          box-sizing: border-box;
          font-family: PingFangSC-Semibold, PingFang SC;
          letter-spacing: -1px;
          align-items: center;
          justify-content: center;
          div {
            font-size: 18px;
            font-weight: 400;
            color: #000000;
            margin-left: 12px;
          }
        }

        .activeitem {
          width: 206px;
          cursor: pointer;
          box-sizing: border-box;
          height: 60px;
          border-radius: 8px;
          display: flex;
          align-items: center;
          letter-spacing: -1px;

          justify-content: center;
          background-color: black;
          border: 1px solid black;

          &_right {
            height: 100%;
            margin-top: 8px;
            margin-left: 12px;

            &_top {
              margin-top: 4px;
              font-size: 18px;
              font-weight: 400;
              color: #ffffff;
              line-height: 26px;
            }

            &_bottom {
              font-size: 12px;
              line-height: 18px;
              color: #ffffff;
              opacity: 0.7;
              margin-top: 2px;
            }
          }
        }

        .activeitem1 {
          width: 206px;
          cursor: pointer;
          padding: 0 0 0 27px;
          box-sizing: border-box;
          height: 60px;
          border-radius: 8px;
          display: flex;
          align-items: center;
          background-color: black;
          border: 1px solid black;

          &_right {
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            color: #ffffff;
            margin-left: 30px;
          }
        }

        .activeitem2 {
          width: 206px;
          cursor: pointer;
          padding: 0 0 0 27px;
          box-sizing: border-box;
          border-radius: 8px;
          height: 60px;
          display: flex;
          align-items: center;
          background-color: black;
          border: 1px solid black;

          img {
          }

          &_right {
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            color: #ffffff;
            margin-left: 33px;
          }
        }

        .small {
          .activeitem1_right {
            margin-left: 20px;
          }
        }

        .small2 {
          .img2 {
            margin-left: 12px;
          }

          .right2 {
            margin-left: 12px;
          }
        }
      }

      &_imgbox {
        margin-left: 50px;
        margin-top: 140px;
      }
    }
  }
}
.install-mask {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  color: #ffffff;
  .arrow {
    position: fixed;
    top: 20px;
    right: 30px;
  }
  .mask-title {
    position: fixed;
    top: 60px;
    left: 40px;
    color: #fff;
    width: 200px;
    height: 28px;
    font-family: PingFangSC-Semibold;
    font-size: 20px;
    font-weight: 600;
  }
  .sub-title1 {
    position: fixed;
    top: 140px;
    left: 40px;
    height: 20px;
    font-family: PingFangSC-Semibold;
    font-size: 14px;
    color: #ffffff;
    text-align: center;
    font-weight: 600;
    img {
      vertical-align: middle;
      margin-left: 10px;
    }
  }
  .sub-title2 {
    position: fixed;
    top: 200px;
    left: 40px;
    height: 20px;
    font-family: PingFangSC-Semibold;
    font-size: 14px;
    color: #ffffff;
    text-align: center;
    font-weight: 600;
  }
  .open-brower-btn {
    position: fixed;
    top: 240px;
    left: 40px;
    width: 188px;
    height: 49px;
    line-height: 49px;
    background: #ffffff;
    border-radius: 25px;
    font-family: PingFangSC-Semibold;
    font-size: 15px;
    color: #222222;
    text-align: center;
    font-weight: bold;
  }
  .close-mask-btn {
    width: 115px;
    height: 38px;
    line-height: 38px;
    font-size: 14px;
    font-family: PingFangSC-Regular;
    background: rgba(216, 216, 216, 0);
    border: 1px solid rgba(255, 255, 255, 1);
    border-radius: 19px;
    text-align: center;
    margin: 400px auto 0;
  }
}
.mindex {
  display: none;
}
@media only screen and (max-width: 600px) {
  .index {
    display: none;
  }

  .mindex {
    display: block;
    width: 100%;
    height: calc(100vh - 48px);
    overflow-y: scroll;
    scroll-behavior: smooth;
    .mobiledialogpic {
      position: fixed;
      bottom: 24px;
      right: 0px;
      cursor: pointer;
      width: 96px;
      height: 96px;
      display: flex;
      align-items: center;
      justify-content: center;
      > img {
        width: 100%;
        height: 100%;
      }
    }
    &_box {
      width: 100%;
      overflow: hidden;
      &_banner1 {
        width: 100%;
        background: #edf1fe;
        height: 100%;

        .banner1 {
          width: 375px;
          margin: auto;
          padding-bottom: 80px;

          &_img {
            width: 311px;
            height: 234px;
            margin: auto;
            display: block;
          }

          &_stitle {
            margin-left: 32px;
            margin-top: 19px;
            font-size: 14px;
            font-weight: 600;
            color: #1f2329;
            line-height: 20px;
          }

          &_btitle {
            margin-left: 32px;
            width: 311px;
            margin-top: 8px;
            font-size: 24px;
            color: #000000;
            line-height: 36px;
          }

          &_text {
            width: 311px;
            margin: auto;
            margin-top: 13px;
            font-size: 13px;
            color: #51565d;
            line-height: 18px;
          }

          &_btnbox {
            width: 256px;
            margin: auto;
            margin-top: 50px;

            &_item1 {
              width: 100%;
              height: 49px;
              background: #0081ff;
              box-shadow: 20px 24px 30px 0px rgba(71, 108, 255, 0.2);
              border-radius: 38px;
              display: flex;
              align-items: center;
              justify-content: center;

              div {
                font-size: 16px;
                font-weight: 600;
                color: #ffffff;
                line-height: 24px;
              }

              img {
                width: 16px;
                height: 16px;
                margin-left: 7px;
              }
            }

            &_item2 {
              background: rgba(0, 0, 0, 0);
              box-shadow: 20px 24px 30px 0px rgba(71, 108, 255, 0.2);
              border-radius: 38px;
              border: 2px solid #1a2236;
              margin-top: 16px;
              width: 100%;
              height: 47px;
              display: flex;
              justify-content: center;
              align-items: center;

              div {
                font-size: 16px;
                font-weight: 600;
                color: #1a2236;
                line-height: 22px;
                text-shadow: 20px 24px 30px rgba(71, 108, 255, 0.2);
              }
            }
          }
        }
      }

      &_banner2 {
        width: 100%;
        background: #edf1fe;
        padding-bottom: 43px;

        .banner2 {
          width: 355px;
          margin: auto;

          &_btitle {
            width: 100%;
            text-align: center;
            font-size: 28px;
            color: #000000;
            line-height: 45px;
          }

          &_text {
            width: 100%;
            text-align: center;
            margin-top: 10px;
            font-size: 14px;
            color: #51565d;
            line-height: 24px;
          }

          &_img {
            margin: auto;
            width: 375px;
            height: 500px;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }

      &_banner3 {
        width: 100%;

        // background-color: #EDF1FE;
        .banner3 {
          width: 100%;
          height: 100%;
          margin: auto;
          background-color: #000000;
          .banner3_img1 {
            width: 299px;
            height: 244px;
            margin-top: 48px;
            margin-left: 38px;
          }
          .banner3_img2 {
            width: 321px;
            height: 269px;
            margin-top: 28px;
            margin-left: 26px;
          }
          .banner3_img3 {
            width: 299px;
            height: 283px;
            margin-top: 30px;
            margin-left: 38px;
          }
          .banner3_img4 {
            width: 355px;
            height: 271px;
            margin-top: 33px;
            margin-left: 11px;
          }
          .banner3_img5 {
            width: 304px;
            height: 239px;
            margin-top: 30px;
            margin-left: 36px;
          }
          &_box {
            background: url("../../static/img/mobile-index/banner32.png")
              no-repeat center;
            background-size: 100% 100%;
            width: 100%;
            height: 100%;

            &_btitle {
              border-radius: 95px;
              box-shadow: 0 -20px 10px 10px rgba(226, 232, 254, 0.8);
              text-align: center;
              margin: auto;
              padding-top: 50px;
              padding-bottom: 47px;
              font-size: 24px;
              font-weight: 600;
              color: #0a1d4c;
              line-height: 24px;
              letter-spacing: 1px;
            }

            &_content {
              width: 355px;
              margin: auto;

              &_title {
                // margin-left: 10px;
                font-size: 16px;
                font-weight: 600;
                color: #1f2329;
                line-height: 24px;
                width: 355px;
                text-align: center;
              }

              &_text {
                margin-left: 32px;
                font-size: 14px;
                color: #7c7c7c;
                line-height: 22px;
                margin-top: 10px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
